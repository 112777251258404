// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 


import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import { EffectCards } from "swiper";
import { EffectFade } from "swiper";
import { Autoplay } from "swiper";

import { TmpButton } from "../buttons/tempButton"

import { gsap } from "gsap";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-cards";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-fade";

export const SwiperComponent = (props) => {
    return (
        <div className='SwiperComponentCoreWrapper'>
            {props.compData.swiperSettings.bgLayer ? <div className="bgLayer"></div> : null}
            <Swiper
                className={`SwiperComponentCore  ${props.compData.className} ${props.compData.swiperSettings.fullWidth ? `swiperFullWidth` : ''} `}
                modules={[Autoplay, EffectFade, EffectCards, Pagination, Navigation]}
                effect={props.compData.swiperSettings.effect}
                breakpoints={{
                    0: {
                        slidesPerView: props.compData.swiperSettings.mobile.slidesPerView, spaceBetween: props.compData.swiperSettings.mobile.spaceBetween,
                    },
                    875: {
                        slidesPerView: props.compData.swiperSettings.desktop.slidesPerView, spaceBetween: props.compData.swiperSettings.desktop.spaceBetween,
                    }
                }}
                centeredSlides={props.compData.swiperSettings.centeredSlides}
                loop={props.compData.swiperSettings.loop}
                speed={500}
                autoplay={props.compData.swiperSettings.autoplay}
                navigation={true}
                grabCursor={true}
                slideToClickedSlide={true}
                spaceBetween={40}

            // onSwiper={(swiper) => console.log(swiper)}
            >
                {props.compData.slides.map((slide, index) => (
                    <SwiperSlide key={index} id={`thumb${index}`}
                        className={`swiperSlide swiperSlide${slide.id} thumbnail`} >
                        {props.compData.slideSettings.backgroundImage ? <>
                            <img loading="lazy" className='backgroundImg' src={props.localImages ? props.allLocalImages[index] : props.compData.slides[index].image} />
                            <div className='gradient'></div>
                        </>
                            : null}

                        {props.compData.slideSettings.placeholderImage ?
                            <div className='placeholder'>
                                <img loading="lazy" className='img' src={props.localImages ? props.allLocalImages[index] : props.compData.slides[index].image} />
                            </div>
                            : null
                        }
                        {props.compData.slideSettings.showTitle ? <h2 className='title'>{props.compData.slides[index].title}</h2> : null}
                        {props.compData.slideSettings.button.show ?
                            <TmpButton
                                title={props.compData.slides[index].button.title}
                                scrollTo={props.compData.slideSettings.scrollTo}
                                target={props.compData.slideSettings.openNewTab}
                                className={props.compData.slideSettings.button.className}
                                anim={props.compData.slideSettings.button.icon.animation}
                                icon={props.compData.slideSettings.button.icon.icon}
                                link={props.compData.slides[index].button.link.anchorID}
                                exitClick={props.compData.slides[index].button.link.exitClick}
                            />
                            : null}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div >

    )
}