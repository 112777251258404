// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link } from 'react-scroll'
import { TmpButton } from "../buttons/tempButton"

export const ImageComponent = (props) => {
    const imageCompRef = useRef();
    const imageRef = useRef();
    const [imageHoverd, setImageHoverd] = useState();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
        }, imageCompRef)
        return () => ctx.revert();
    }, []);


    return (
        <div ref={imageCompRef} className="ImageComponentCore"
            onMouseEnter={() => {
                gsap.to(imageRef.current, { scale: 1.05, })
            }}
            onMouseLeave={() => {
                gsap.to(imageRef.current, { scale: 1 })
            }}
        >

            {props.compData.bgLayer ? <div className="bgLayer"></div> : null}

            <Link to={props.compData.button.link.anchorID} spy={true} smooth={true} offset={-100} duration={500}>
                <img style={{ position: 'relative' }} ref={imageRef}
                    className={`${props.compData.coverBackgroundImage ? 'bgImg' : ''}`}
                    src={props.localImages ? props.src : props.compData.image} />
            </Link>


            {props.compData.showTitle ? <div>{props.compData.title}</div> : null}
            {props.compData.buyButton ?
                <TmpButton
                    link={props.compData.button.link.anchorID}
                    exitClick={props.compData.button.link.exitClick}
                    scrollTo={props.compData.button.link.scrollTo}
                    icon={props.compData.button.icon.icon}
                    anim="scale"
                    type="round"
                    className="dsBtn tmpBtn"
                />
                : null}
            <>
                {props.compData.button.show ?
                    <TmpButton
                        title={props.compData.button.title}
                        link={props.compData.button.link.anchorID}
                        exitClick={props.compData.button.link.exitClick}
                        scrollTo={props.compData.button.link.scrollTo}
                        icon={props.compData.button.icon.icon}
                        anim={props.compData.button.icon.animation}
                        className={props.compData.button.className}
                    />
                    : null
                }
            </>
        </div>
    )
}