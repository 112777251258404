// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

import React, { Fragment } from 'react';
import Media from 'react-media';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link } from 'react-scroll'

import { ImageComponent } from '../../../../components/ImageComponent/ImageComponentCore';
import { SwiperComponent } from '../../../../components/swiperComponent/swiperComponentCore';
import { TextComponent } from '../../../../components/textComponent/textComponentCore';

import textComp from '../components/TextComponent.json'
import imageComp from '../components/ImageComponent.json'
import swiperComp from '../components/SwiperComponent.json'
import { TmpButton } from '../../../../components/buttons/tempButton';

import bgImageHz from '../../assetsDfw/bg-hero-desktop.webp'
import bgImageVt from '../../assetsDfw/bg-hero-mobile.webp'

gsap.registerPlugin(ScrollTrigger);

export const DfwCoreInfo = (props) => {
    const [vwMaxMedium, setVwMaxMedium] = useState();

    const StandaardInfoRef = useRef();
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            // gsap.set('.TextComponentCore >*', {  y: '5vh', })

            gsap.from('.DfwStandaardInfo .TextComponentCore ', {
                opacity: 0, duration: 2,
                onComplete: () => {
                    var fadeInTl = gsap.timeline({
                        defaults: { ease: 'Power2.easeInOut', },
                        scrollTrigger: {
                            trigger: StandaardInfoRef.current,
                            scrub: true,
                            start: "0% 100%", end: '0% 30%',
                            // markers: true,
                        }
                    })
                    fadeInTl.from('.TextComponentCore >*', { y: 0, stagger: .25, duration: 1.5, })

                }
            })

            if (props.id === 2) {
                gsap.from('#StandaardInfo2 .ImageComponentCore', { opacity: 0, })
                gsap.set('#StandaardInfo2 .ImageComponentCore', { y: '-35vh', scale: .85, })
                gsap.to('#StandaardInfo2 .ImageComponentCore', {
                    y: '0', scale: 1,
                    scrollTrigger: {
                        trigger: StandaardInfoRef.current,
                        scrub: true,
                        start: "0% 80%", end: '50% 65%',
                        // markers: true,
                    }
                })

            }
        }, StandaardInfoRef)
        return () => ctx.revert();
    }, []);

    function vidLinkHoverIn(num) {
        gsap.to(`.vidMenuLinkUnderline${num}`, { width: '100%' })
    }
    function vidLinkHoverOut(num) {
        gsap.to(`.vidMenuLinkUnderline${num}`, { width: '0%' })
    }

    return (
        <section ref={StandaardInfoRef}
            className={`StandaardInfo DfwStandaardInfo ${props.checkIfBasic ? 'DFWINFOBASIC' : 'DFWINFOVIDEO'} `}
            id={`StandaardInfo${props.id}`}
        >
            <Media queries={{ maxMedium: props.vw.maxMedium }} onChange={matches => matches.maxMedium ? setVwMaxMedium(true) : setVwMaxMedium(false)} />
            {props.checkIfBasic ?
                <img src={vwMaxMedium ? bgImageVt : bgImageHz} className="bgImage" />
                : null
            }
            <div className="gradientBgImage"></div>
            <section className="parent">
                {
                    props.checkIfBasic ?
                        <section id="DfwHeaderBasic">
                            <div className="DfwHeaderlinkItem">
                                <Link className={`vidMenuLink vidMenuLink1`}
                                    onMouseOver={() => { vidLinkHoverIn(1) }}
                                    onMouseLeave={() => { vidLinkHoverOut(1) }}
                                    to="DfwCoreJwPlaceHodler" spy={true} smooth={true} offset={-50} duration={500}>Trailer</Link>
                                <span className={`vidMenuLinkUnderline vidMenuLinkUnderline1`} ></span>
                            </div>
                            <div className="DfwHeaderlinkItem">
                                <Link className={`vidMenuLink vidMenuLink2`}
                                    onMouseOver={() => { vidLinkHoverIn(2) }}
                                    onMouseLeave={() => { vidLinkHoverOut(2) }}
                                    to={!props.checkIfBasic ? "StandaardImageViewer" : "DfwSingleImageSection"} spy={true} smooth={true} offset={0} duration={500}>
                                    {!props.checkIfBasic ? "Foto's" : 'Foto'}</Link>
                                <span className={`vidMenuLinkUnderline vidMenuLinkUnderline2`} ></span>
                            </div>
                            {!vwMaxMedium ?
                                <TmpButton
                                    title={props.buttonTxt}
                                    link="DfwTicketModule"
                                    scrollTo={true}
                                    openNewTab={false}
                                    icon={"confirmation_number"}
                                    anim="top"
                                    type=""
                                    className="tmpBtn vl-button dfwBtn"
                                />
                                : null}
                        </section>
                        : null
                }
                <section className='inner'>
                    <div className='innerBg'></div>
                    <aside className='ph-1'  >
                        {props.checkIfBasic ?
                            <img src={props.logoFilmRelease} className="infoFilmReleaseLogo" />
                            : null}
                        {
                            textComp.map((component, index) => (
                                props.id === index ?
                                    <TextComponent
                                        key={index}
                                        compData={component}
                                        buttonTxt={props.buttonTxt}
                                        localContent={true}
                                        title={props.title}
                                        txt={props.txt}
                                    />
                                    : null
                            ))
                        }
                    </aside>
                    {props.columns >= 2 ?
                        <aside className='ph-2'>
                            {props.swiperComp ?
                                swiperComp.map((component, index) => (
                                    props.id === index ?
                                        < SwiperComponent key={index} compData={component} />
                                        : null
                                ))
                                : null
                            }
                            {
                                props.imageComp ?
                                    imageComp.map((component, index) => (
                                        props.id === index ?
                                            < ImageComponent
                                                key={index}
                                                src={props.posterFilmRelease}
                                                localImages={props.localImages}
                                                compData={component} />
                                            : null
                                    ))
                                    : null
                            }
                        </aside>
                        : null
                    }
                </section>

            </section>
        </section >
    )
}