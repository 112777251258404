import 'material-icons/iconfont/outlined.css';
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import { Link } from 'react-scroll'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);


export const TmpButtonRound = (props) => {
    return (
        <div className='dsRoundBtn' >
            {/* {props.dashedline ? <img src={dashedline} className="dashedLine" /> : null} */}
            <div className='material-icons-outlined arrowInside arrowInside1'>    {props.icon} </div>
        </div>
    )
}
export const TmpButtonText = (props) => {
    return (
        <button className={props.className}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <div className='externalIconOuter'>
                <i>{props.title}</i>
            </div>
            <div className='btnArrowContainer'>
                <div className='material-icons-outlined arrowInside arrowInside1'>
                    {props.icon}
                </div>
                <div className='material-icons-outlined arrowInside arrowInside2'>
                    {props.icon}
                </div>
            </div>
        </button>
    )
}

export const TmpButton = (props) => {
    const [mouseOver, setMouseOver] = useState(false);
    const tl = useRef();
    const btn = useRef();

    const includesTop = props.anim.includes("top") || props.anim.includes("topLeft") || props.anim.includes("topRight")
    const includesLeft = props.anim.includes("left") || props.anim.includes("topLeft") || props.anim.includes("bottomLeft")
    const includesRight = props.anim.includes("right") || props.anim.includes("topRight") || props.anim.includes("bottomRight")
    const includesBottom = props.anim.includes("bottom") || props.anim.includes("bottomLeft") || props.anim.includes("bottomRight")
    const includesScale = props.anim.includes("scale")
    
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            tl.current && tl.current.progress(0).kill();
            tl.current = gsap.timeline({
                defaults: {
                    duration: .4,
                },
            })
                .set(".arrowInside2", {
                    display: includesScale ? "none" : "flex", scale: includesScale ? 1.25 : 1,
                })
                .to(".arrowInside1", {
                    marginTop: includesTop ? "-175%" : 0, marginLeft: includesLeft ? "-175%" : 0, marginRight: includesRight ? "-175%" : 0, marginBottom: includesBottom ? "-175%" : 0,
                    scale: includesScale ? 1.25 : 1,
                })
                .from(".arrowInside2", {
                    marginTop: includesTop ? "175%" : 0, marginLeft: includesLeft ? "175%" : 0, marginRight: includesRight ? "175%" : 0, marginBottom: includesBottom ? "175%" : 0,
                }, "-=.4")
            // .from(".dsBtn", { backgroundColor: "white", duration: .1, }, { backgroundColor: "", duration: .1, }, "-=.8")


        }, btn)
        return () => ctx.revert();
    }, []);

    useEffect(() => {
        tl.current.reversed(mouseOver);
    }, [mouseOver])

    return (
        <>
            {props.scrollTo
                ? <>
                    <div ref={btn} className='parentTmpButton' type={`${props.submitForm ? 'submit' : null}`} onMouseEnter={() => setMouseOver(!mouseOver)}
                        onMouseLeave={() => setMouseOver(!mouseOver)}>
                        <Link to={props.link} spy={true} smooth={true} offset={85} duration={500}>
                            {props.type === "round" ?
                                <TmpButtonRound icon={props.icon}
                                    title={props.title}
                                    anim={props.anim} />
                                : <TmpButtonText icon={props.icon}
                                    onMouseEnter={() => setMouseOver(!mouseOver)}
                                    onMouseLeave={() => setMouseOver(!mouseOver)}
                                    className={props.className}
                                    title={props.title}
                                    anim={props.anim}
                                />
                            }
                        </Link>
                    </div>
                </>
                :
                <a ref={btn} href={props.exitClick} target={props.target ? '_blank' : ''} className='parentTmpButton' onMouseEnter={() => setMouseOver(!mouseOver)}
                    onMouseLeave={() => setMouseOver(!mouseOver)}>
                    {props.type === "round" ?
                        <TmpButtonRound icon={props.icon} />
                        : <TmpButtonText icon={props.icon}
                            onMouseEnter={() => setMouseOver(!mouseOver)}
                            onMouseLeave={() => setMouseOver(!mouseOver)}
                            className={props.className}
                            title={props.title}
                            anim={props.anim}
                        />
                    }
                </a>
            }
        </>
    )
}