import React, { Fragment } from 'react';
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import Media from 'react-media';

import dataFooterComponent from './dataFooter.json'
export const DfwCoreFooter = (props) => {
    var dataFooter = dataFooterComponent.footer


    const [cookiePolicyPp, setCookiePolicyPp] = useState(false);

    return (
        <footer className="StandaardFooter dfwFooter">

            <div className='imageRow'>
                {
                    props.footerIcons.map((image, index) => {
                        return (
                            <div key={index}>
                                {index < props.maxFooterIcons ?
                                    <img src={image} className={`footerImage footerImage${index}`} />
                                    : null
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className='CookiePolicyPP' style={{ display: cookiePolicyPp ? 'flex' : 'none' }}>
                <div className='CookiePolicy-exitbg' onClick={() => { setCookiePolicyPp(false) }}></div>
                <div className='CookiePolicy-inner'>
                    <div className='closeBtn material-icons-outlined icon'
                        onClick={() => { setCookiePolicyPp(false) }}>
                        close
                    </div>
                    <b className='CookiePolicy-title'>Deze site maakt gebruik van cookies die surfgedrag bijhouden.</b>
                    <div className='CookiePolicy-explainer'>
                        Dit zijn analytische cookies. Daarmee krijgen we inzicht in hoe bezoekers de website gebruiken. Deze informatie helpt ons om de site te verbeteren.
                    </div>
                </div>
            </div>
            <div className='columnWrapper' style={{ textAlign: 'center' }}>
                <ul className='firstColumn'>
                    {dataFooter.firstColumn.map((link, index) => {
                        return <li key={index}><a className='linkFooter' href={link.url} target="_blank">{link.title} </a></li>
                    })}

                </ul>
                <ul className='secondColumn' style={{ textAlign: 'center' }}>
                    {dataFooter.secondColumn.map((link, index) => {
                        return <li key={index}><a className='linkFooter' href={link.url} target="_blank">{link.title}</a></li>
                    })}

                </ul>
                <ul className='thirdColumn' style={{ textAlign: 'center' }}>
                    <li>
                        <a className='linkFooter' onClick={() => { setCookiePolicyPp(true) }}> Cookie Policy</a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}