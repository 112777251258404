import { TmpButton } from "../buttons/tempButton"
import swiperComp from '../../core/StandaardRetail/components/SwiperComponent.json'
import { gsap } from "gsap";
import { useLayoutEffect, useRef, useState } from "react";

import { SplitText } from "gsap/SplitText"
gsap.registerPlugin(SplitText);
export const TextComponent = (props) => {
    const txtCompRef = useRef();
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if (props.fadeIn) {
                var fadeInTl = gsap.timeline({
                    defaults: { ease: 'Power2.easeInOut', },
                    scrollTrigger: {
                        trigger: txtCompRef.current,
                        scrub: true,
                        start: "0% 85%", end: '0% 50%',
                        // markers: true,
                    }
                })
                fadeInTl.from('.TextComponentCore >*', { opacity: 0, y: '5vh', stagger: .25, duration: 1.5, })
            }
        }, txtCompRef)
        return () => ctx.revert();
    }, []);
    return (
        <div className={`TextComponentCore ${props.compData.textCenter ? `textComponentCenter` : ''}`}
            ref={txtCompRef}
        >
            {
                props.compData.title.htmlTag === "h1" ? <h1 className={`${props.compData.staggerTitle ? 'titleStagger' : ''}`}>{props.localContent ? props.title : props.compData.title.text}</h1> : ''
            }
            {
                props.compData.title.htmlTag === "h2" ? <h2 className={`${props.compData.staggerTitle ? 'titleStagger' : ''}`}>{props.localContent ? props.title : props.compData.title.text}</h2> : ''
            }
            {
                props.compData.title.htmlTag === "h3" ? <h3 className={`${props.compData.staggerTitle ? 'titleStagger' : ''}`}>{props.localContent ? props.title : props.compData.title.text}</h3> : ''
            }
            {
                props.compData.title.htmlTag === "h4" ? <h4 className={`${props.compData.staggerTitle ? 'titleStagger' : ''}`}>{props.localContent ? props.title : props.compData.title.text}</h4> : ''
            }
            {
                props.compData.title.htmlTag === "div" ? <div className={`${props.compData.staggerTitle ? 'titleStagger' : ''}`}>{props.localContent ? props.title : props.compData.title.text}</div> : ''
            }
            {
                props.compData.para !== '' ? props.compData.para.map((index, key) => {
                    return <p key={key} className={`${props.compData.staggerPara ? 'paraStagger' : null}`}>{props.localContent ? props.txt : index}</p>
                }) : null
            }
            <div className="btnWrapper">
                {props.compData.button.show ?
                    <>
                        <TmpButton
                            title={props.buttonTxt}
                            link={props.compData.button.link.anchorID}
                            exitClick={props.compData.button.link.exitClick}
                            scrollTo={props.compData.button.link.scrollTo}
                            icon={props.compData.button.icon.icon}
                            anim={props.compData.button.icon.animation}
                            className={props.compData.button.className}
                        />
                    </>
                    : null}
                {props.compData.button.showRetailWidget ?
                    <div className="retailWidget">
                        <img src={swiperComp[0].slides[0].image}></img>
                        <img src={swiperComp[0].slides[1].image}></img>
                        <img src={swiperComp[0].slides[2].image}></img>

                        {/* {swiperComp[0].slides.map((item, index) => (
                            <img src={item.image}></img>
                        ))} */}
                    </div>
                    : null}
            </div>


        </div>
    )
}