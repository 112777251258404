// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

import React, { Fragment } from 'react';
import Media from 'react-media';
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { VideoComponent } from '../../../../components/videoComponent/videoComponentCore';
import { gsap } from "gsap";
import { VideoPopUpComponent } from '../../../../components/videoComponent/videoPopUpComponent';
import { Link } from 'react-scroll'
import dataHeaderComponent from '../components/dataHeader.json'
import { TmpButton } from '../../../../components/buttons/tempButton';
import bgImageHz from '../../assetsDfw/bg-hero-desktop.webp'
import bgImageVt from '../../assetsDfw/bg-hero-mobile.webp'


export const DfwCoreHero = (props, jwPlayerRef) => {
    var dataHeader = dataHeaderComponent.header
    const vidPlayerRef = useRef();
    const vidCompRef = useRef();
    const StandaardVideoAllRef = useRef();
    const [vidLinkHover, setVidLinkHover] = useState(false);
    const [vwMaxMedium, setVwMaxMedium] = useState();

    let jwPlayerPaused = true;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            var jwPlayerToggle = gsap.timeline({
                defaults: {duration: 1,},
                scrollTrigger: {
                    trigger: vidCompRef.current,
                    scrub: true,
                    start: "0% 55%", end: '100% 55%',
                    // markers: true,
                }
            })
            gsap.from('.singleVideoPlaceholder', {
                rotateX: '50vw', scale: .65,
                ease: "Power3.easeIn",
                scrollTrigger: {
                    trigger: vidCompRef.current,
                    scrub: true,
                    start: "0% 100%", end: '50% 75%',
                    // markers: true,
                }
            })

        }, [vidCompRef, jwPlayerPaused])
        return () => ctx.revert();
    }, []);
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.set('#StandaardVideoAll .StandaardVideo .title', { y: '5vh', opacity: 0, })
            gsap.to('#StandaardVideoAll .title', {
                y: '0', opacity: 1,
                scrollTrigger: {
                    trigger: StandaardVideoAllRef.current,
                    scrub: true,
                    start: "0% 80%", end: '40% 65%',
                    // markers: true,
                }
            })
        })
        return () => ctx.revert();
    }, []);

    function vidLinkHoverIn(num) {
        gsap.to(`.vidMenuLinkUnderline${num}`, { width: '100%' })
    }
    function vidLinkHoverOut(num) {
        gsap.to(`.vidMenuLinkUnderline${num}`, { width: '0%' })
    }
    return (
        <div ref={StandaardVideoAllRef} id="DfwHero">
            <Media queries={{ maxMedium: props.vw.maxMedium }} onChange={matches => matches.maxMedium ? setVwMaxMedium(true) : setVwMaxMedium(false)} />
            <img src={vwMaxMedium ? bgImageVt : bgImageHz} className="bgImage" />
            {vwMaxMedium ? null : <VideoPopUpComponent jwPlayerRef={jwPlayerRef} data={props.bunnyTeaseVideo} vw={props.vw} title={''}
                hideHeader={true}
                autoPlay={props.autoPlay}
                localVideo={props.localVideo}
                videoFilmRelease={props.bunnyTeaseVideo}
                jwVideoID={props.jwVideoID}
                jwPlayerID={props.jwPlayerID}
            />}
            <div id="StandaardOverlay">
                <div id="innerStandaardOverlay">
                    {vwMaxMedium ?
                        <video id="mobileHeroVideo" muted autoPlay playsInline loop  >
                            <source src={props.bunnyTeaseVideo} type="video/mp4" />
                        </video>
                        
                        : null}
                    <div id="vidPlayHeader">
                        <img src={props.logoFilmRelease} id="logoFilmRelease" />
                        <div className='vidPlayMenuWrapper'>
                            <div style={{ position: 'relative' }}>
                                <Link className={`vidMenuLink vidMenuLink1`}
                                    onMouseOver={() => { vidLinkHoverIn(1) }}
                                    onMouseLeave={() => { vidLinkHoverOut(1) }}
                                    to="StandaardInfo1" spy={true} smooth={true} offset={-50} duration={500}>Synopsis</Link>
                                <span className={`vidMenuLinkUnderline vidMenuLinkUnderline1`} ></span>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <Link className={`vidMenuLink vidMenuLink2`}
                                    onMouseOver={() => { vidLinkHoverIn(2) }}
                                    onMouseLeave={() => { vidLinkHoverOut(2) }}
                                    to="DfwCoreJwPlaceHodler" spy={true} smooth={true} offset={-50} duration={500}>Bekijk trailer</Link>
                                <span className={`vidMenuLinkUnderline vidMenuLinkUnderline2`} ></span>
                            </div>
                            <TmpButton
                                title={props.buttonTxt}
                                anim={dataHeader.button.icon.animation}
                                icon={dataHeader.button.icon.icon}
                                className={dataHeader.button.className + ' dfwBtn'}
                                scrollTo={dataHeader.button.link.scrollTo}
                                link={dataHeader.button.link.anchorID}
                                exitClick={dataHeader.button.link.exitClick}
                                target={dataHeader.button.link.openNewTab}
                            />
                        </div>
                    </div>
                    <div id="vidDescription">
                        <div className='heroInfo'>
                            <h1 className='releaseTitle'>{props.title}</h1>
                            <div className='filmInfoIconWrapper'>
                                <div className='filmInfoContainer'>
                                    <div className='material-icons-outlined'> schedule</div>
                                    <p>{props.lengteFilm}</p>
                                </div>
                                <div className='filmInfoContainer'>
                                    <div className='material-icons-outlined'>today</div>
                                    <p>{props.datumFilm}</p>
                                </div>
                                <div className='filmInfoContainer'>
                                    <div className='material-icons-outlined'> label</div>
                                    <p>{props.labelFilm}</p>
                                </div>
                            </div>
                            <TmpButton
                                title={props.buttonTxt}
                                anim={dataHeader.button.icon.animation}
                                icon={dataHeader.button.icon.icon}
                                className={dataHeader.button.className + ' dfwBtn'}
                                scrollTo={dataHeader.button.link.scrollTo}
                                link={dataHeader.button.link.anchorID}
                                exitClick={dataHeader.button.link.exitClick}
                                target={dataHeader.button.link.openNewTab}
                            />
                        </div>
                        <span></span>
                        <article>
                            <q>
                                {props.oneLiner.map((content, index) => {
                                    return (
                                        <p className="staggerPara oneliner" key={index} >{content}</p>
                                    )
                                })}
                            </q>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}