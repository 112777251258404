import React from 'react';

import JWPlayer from '@jwplayer/jwplayer-react';
import { useEffect, useLayoutEffect, useState, useRef } from "react";

import Media from 'react-media';

// import data from './../videoData.json'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { Navigation } from "swiper";

import { gsap } from "gsap";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { TmpButton } from '../buttons/tempButton';
class PlayerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.players = {};
        this.refSwiper = React.createRef();
        this.refJwPlayer0 = React.createRef();
        this.onBeforePlay = this.onBeforePlay.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.playerMountedCallback = this.playerMountedCallback.bind(this);
        this.playerUnmountingCallback = this.playerUnmountingCallback.bind(this);
        this.state = {
            count: 0,
            hasClicked: false,
        };
    }


    // Registers players as they mount
    playerMountedCallback({ player, id }) {
        if (this.state.hasClicked !== true) {
            this.players[id] = player.pause();
        } else {
            this.players[id] = player.play();
        }
    }

    // Nulls registered players as they unmount
    playerUnmountingCallback({ id }) {
        this.players[id] = null;

    }

    // Prevent multiple players from playing simultaneously
    onBeforePlay(event) {
        Object.keys(this.players).forEach(playerId => {
            const player = this.players[playerId];
            const isPlaying = player.getState() === 'playing';
            if (isPlaying) {
                player.pause();

            } else {
                player.play();
                // console.log()
                gsap.set(`#thumb0 .dsRoundBtn`, { backgroundColor: "", color: "" })

            }
        });

    }

    // Put teal colored outline on currently playing player, remove it from all other players.
    onPlay(event) {
        Object.keys(this.players).forEach(playerId => {
            const player = this.players[playerId];
            const container = player.getContainer();
            if (player.getState() === 'playing') {
                // container.style.border = '15px solid #00FFFF';
                this.setState({ hasClicked: this.state.hasClicked = true })

            } else {
                container.style.border = '';
            }
        });
    }

    render() {
        // Re-usable defaults to use between multiple players.
        const configDefaults = { width: 350, height: 197 };

        let dataInput = this.props.data
        if (this.state.hasClicked === false) {
            setTimeout(() => {
                // gsap.set("#thumb0", { height: '10em', scale: 1, opacity: 1, border: 'solid 1px white' })
                // gsap.set("#thumb0 .thumbImg", { width: '150%' })
                gsap.set(`.thumbnail .dsRoundBtn`, { backgroundColor: "white", color: "black" })
                // gsap.set(`#thumb0 .dsRoundBtn`, { backgroundColor: "", color: "" })

                if (this.state.count === 0) {
                    // gsap.set('.thumbnail', { opacity: .25, })
                    // gsap.set('#thumb0', { opacity: 1, })

                }
            }, 100);
        } else {
            gsap.to('.VideoComponent .swiperWrapper', {
                marginTop: '1em',
                width: '85%'
            })
        }

        function activeThumb(num) {
            // gsap.set(".thumbnail", { height: '', scale: 1, border: '', opacity: .25, })
            gsap.set(".thumbnail .thumbImg", { width: '110%', height: '110%', })
            // gsap.set(`#thumb${num}`, { height: '10em', scale: 1, opacity: 1, border: 'solid 1px white' })
            gsap.set(`#thumb${num} .thumbImg`, { width: '150%', })

            gsap.set(`.thumbnail .dsRoundBtn`, { backgroundColor: "white", color: "black" })
            gsap.set(`#thumb${num} .dsRoundBtn`, { backgroundColor: "", color: "" })
            gsap.set(`.thumbnail .dsRoundBtn`, { backgroundColor: "white", color: "black" })
            gsap.set(`#thumb${num} .dsRoundBtn`, { backgroundColor: "", color: "" })
            gsap.set(`#thumb${num} img`, {
                opacity: 1, scale: 1, onComplete: () => {
                    gsap.to(`#thumb${num} img`, { opacity: .5, scale: 1.2, })

                }
            })

            gsap.set(`thumbSubDescription${num}`, {
                opaicty: 1,
            })

        }
        return (
            <>
                <div className='titleContainer'>
                    <h3 className='title' >{dataInput.thumbs[this.state.count].title}</h3>
                    <h3 className='aantal'>{dataInput.thumbs[this.state.count].id + 1} / {dataInput.thumbs.length}</h3>
                </div>
                <div className='videoPlayer'>
                    <div className='videoPlayerBackgroundColor'></div>
                    <div className='mainPlayerPlaceholder'>
                        {this.state.count === 0 && this.state.hasClicked === false ? <JWPlayer
                            config={configDefaults}
                            onBeforePlay={this.onBeforePlay}
                            onPlay={this.onPlay}
                            didMountCallback={this.playerMountedCallback}
                            willUnmountCallback={this.playerUnmountingCallback}
                            file={`https://cdn.jwplayer.com/manifests/${dataInput.videos[0]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[0]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                        /> :
                            null
                        }
                        {this.state.count === 0 && this.state.hasClicked === true ? <JWPlayer
                            config={configDefaults}
                            onBeforePlay={this.onBeforePlay}
                            onPlay={this.onPlay}
                            didMountCallback={this.playerMountedCallback}
                            willUnmountCallback={this.playerUnmountingCallback}
                            file={`https://cdn.jwplayer.com/manifests/${dataInput.videos[0]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[0]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                        /> :
                            null
                        }
                        {this.state.count === 1 ? <JWPlayer
                            config={configDefaults}
                            onBeforePlay={this.onBeforePlay}
                            onPlay={this.onPlay}
                            didMountCallback={this.playerMountedCallback}
                            willUnmountCallback={this.playerUnmountingCallback}
                            file={`https://cdn.jwplayer.com/manifests/${dataInput.videos[1]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[1]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                        /> :
                            null
                        }
                        {this.state.count === 2 ? <JWPlayer
                            config={configDefaults}
                            onBeforePlay={this.onBeforePlay}
                            onPlay={this.onPlay}
                            didMountCallback={this.playerMountedCallback}
                            willUnmountCallback={this.playerUnmountingCallback}
                            file={`https://cdn.jwplayer.com/manifests/${dataInput.videos[2]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[2]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                        /> :
                            null
                        }
                        {this.state.count === 3 ? <JWPlayer
                            config={configDefaults}
                            onBeforePlay={this.onBeforePlay}
                            onPlay={this.onPlay}
                            didMountCallback={this.playerMountedCallback}
                            willUnmountCallback={this.playerUnmountingCallback}
                            file={`https://cdn.jwplayer.com/manifests/${dataInput.videos[3]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[3]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                        /> :
                            null
                        }
                        {this.state.count === 4 ? <JWPlayer
                            config={configDefaults}
                            onBeforePlay={this.onBeforePlay}
                            onPlay={this.onPlay}
                            didMountCallback={this.playerMountedCallback}
                            willUnmountCallback={this.playerUnmountingCallback}
                            file={`https://cdn.jwplayer.com/manifests/${dataInput.videos[4]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[4]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                        /> :
                            null
                        }
                    </div>

                    <div className='players-container' >
                        <Media queries={{ small: this.props.vw.small }}>
                            {matches =>
                                matches.small ? (
                                    <>
                                        <div className='swiperWrapper'>
                                            <div className='swiperTitleContainer'>
                                                <div className='title'>Gerelateerde video’s</div>
                                            </div>
                                            <Swiper
                                                ref={this.refSwiper}
                                                className="dfltSwiperSettings vidSwiper"
                                                modules={[Pagination, Navigation]}
                                                speed={500}
                                                pagination={false}
                                                navigation={true}
                                                centeredSlides={true}
                                                slideToClickedSlide={true}
                                                spaceBetween={40}
                                                // onSwiper={(swiper) => console.log(swiper)}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 1.55, spaceBetween: 15,
                                                    },
                                                    700: {
                                                        slidesPerView: 3.5, spaceBetween: 20, centeredSlides: false
                                                    },
                                                }}
                                            >
                                                {dataInput.videos.map((slide, key) => (
                                                    <SwiperSlide key={key} id={`thumb${key}`}
                                                        className={`swiperSlide swiperSlide${slide.id} thumbnail`} >
                                                        <img className='slideThumb thumbImg' onClick={() => {
                                                            this.setState({ hasClicked: this.state.hasClicked = true })
                                                            this.setState({ count: this.state.count = key })
                                                            activeThumb(key)


                                                        }} src={`https://cdn.jwplayer.com/thumbs/${slide}-640.jpg`} />
                                                        {
                                                            this.state.hasClicked
                                                                ? <TmpButton title="" type="round" icon={this.state.count === key ? "graphic_eq" : "play_circle"} anim="scale" className="dsBtn tmpBtn" link="" />
                                                                : <TmpButton title="" type="round" icon={"play_circle"} anim="scale" className="dsBtn tmpBtn" link="" />
                                                        }
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <div className='relatedVideos'>
                                            <div className='title'>Gerelateerde video’s</div>
                                        </div>
                                        <div className='innerScroll'>
                                            <div id="thumbWrapper" className='thumbWrapper'>
                                                {dataInput.thumbs.map((thumb, key) => {
                                                    return (
                                                        <div className='thumbItem' key={key}>
                                                            <div className='thumbContainer' key={thumb.id}
                                                                onClick={() => {
                                                                    this.setState({ hasClicked: this.state.hasClicked = true })
                                                                    this.setState({ count: this.state.count = thumb.id })
                                                                    activeThumb(thumb.id)
                                                                }} >
                                                                <div id={`thumb${thumb.id}`} className='thumbnail' >
                                                                    <img className='thumbImg' src={`https://cdn.jwplayer.com/thumbs/${dataInput.videos[thumb.id]}-640.jpg`} alt={`thumb-video${thumb.id}`} />
                                                                    {
                                                                        this.state.hasClicked
                                                                            ? <TmpButton title="" type="round" icon={this.state.count === thumb.id ? "graphic_eq" : "play_circle"} anim="scale" className="dsBtn tmpBtn" link="" />
                                                                            : <TmpButton title="" type="round" icon={"play_circle"} anim="scale" className="dsBtn tmpBtn" link="" />
                                                                    }
                                                                </div>
                                                                <div className={`thumbSubDescription thumbSubDescription${thumb.id}`}>
                                                                    <div className='title'>{thumb.title}</div>
                                                                    <div className='duration'>{thumb.duration}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </Media>
                    </div>
                </div>
            </>
        );
    }
}
export default PlayerContainer;