import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useState, useRef } from "react";

import { TmpButton } from '../../../components/buttons/tempButton';
import dataHeaderComponent from './dataHeader.json'
// import videoComponentData from '../components/videoComponent.json'
import videoComponentData from '../dfwHero/components/videoComponent.json'

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export const DfwHeader = (props) => {
    var dataHeader = dataHeaderComponent.header
    var dataVideo = videoComponentData.videoComponent

    const refApp = useRef();
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.from(refApp.current, {
                scrollTrigger: {
                    start: "0% 0%", end: "10% 0%",
                    scrub: true,
                    // markers: true,
                },
                y: '-10em',
            })
        }, refApp)
        return () => ctx.revert();
    }, []);
    return (
        <header ref={refApp} className='dfwHeader'>
            <div className='headerInfo'>
                <h4>{props.datum}</h4>
                <TmpButton
                    title={props.buttonTxt}
                    anim={dataHeader.button.icon.animation}
                    icon={dataHeader.button.icon.icon}
                    className={dataHeader.button.className + ' dfwBtn'}
                    scrollTo={dataHeader.button.link.scrollTo}
                    link={dataHeader.button.link.anchorID}
                    exitClick={dataHeader.button.link.exitClick}
                    target={dataHeader.button.link.openNewTab}
                />
             
            </div>
            <div className='socialMediaWrapper'>
                {props.socialUrls[0] !== "" ? <a className='socialMediaIcon fbIcon' href={props.socialUrls[0]} target="_blank" rel="noopener noreferrer"><img src={props.icons[0]} alt="facebook-icon" /></a> : null}
                {props.socialUrls[1] !== "" ? <a className='socialMediaIcon instaIcon' href={props.socialUrls[1]} target="_blank" rel="noopener noreferrer"><img src={props.icons[1]} alt="instagram-icon" /></a> : null}
                {props.socialUrls[2] !== "" ? <a className='socialMediaIcon ytbIcon' href={props.socialUrls[2]} target="_blank" rel="noopener noreferrer"><img src={props.icons[2]} alt="youtube-icon" /></a> : null}
                {props.socialUrls[3] !== "" ? <a className='socialMediaIcon tiktokIcon' href={props.socialUrls[3]} target="_blank" rel="noopener noreferrer"><img src={props.icons[3]} alt="youtube-icon" /></a> : null}
            </div>
        </header>
    )
}