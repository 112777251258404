import { gsap } from "gsap";
import Media from 'react-media';

import { useEffect, useLayoutEffect, useState, useRef } from "react";

import { TmpButton } from '../../components/buttons/tempButton';
import JWPlayer from '@jwplayer/jwplayer-react';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import MouseFollower from "mouse-follower";
MouseFollower.registerGSAP(gsap);

export const VideoPopUpComponent = (props) => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [vwMaxMedium, setVwMaxMedium] = useState();
    const VideoPopUpRef = useRef();
    const overlayTL = useRef();
    const overlayRef = useRef();
    const jwPlayerRef = useRef();
    useLayoutEffect(() => {

        const ctx = gsap.context(() => {
            // const cursor = new MouseFollower({
            //     className: 'mf-cursor mf-cursor-videoHover',
            // });
            const cursor = new MouseFollower({
                className: 'mf-cursor  mf-cursor-test',
            });
            const el = document.querySelector('.videoPopUpMfLayer');
            const vid = document.getElementById('videoPopUpElement')

            if (props.autoPlay === true) {
                vid.play();
            } else {
                vid.pause();
            }

            el.addEventListener('mouseenter', () => {
                cursor.show();
                cursor.setText('Bekijk!');
                cursor.setSkewing(1.2);
                gsap.to('.videoPopUpElement', { scale: 1.1, opacity: .5 })
                if (props.autoPlay === true) {
                    vid.pause();
                } else {
                    vid.play();
                }

                gsap.to('.videoPopUpContainer .videoPopUpWrapper .parentTmpButton', { scale: 1.25, })
                gsap.to('.videoPopUpContainer .parentTmpButton', { opacity: 1, })
                gsap.to('.videoPopUpContainer .dsRoundBtn ', { backgroundColor: '', duration: .35, ease: 'linear', yoyo: true, repeat: 2, })
                gsap.to('.videoPopUpContainer .dsRoundBtn div', { color: 'white' })

            });

            el.addEventListener('mouseleave', () => {
                cursor.hide();
                cursor.removeText();
                gsap.to('.videoPopUpElement', { scale: 1.025, opacity: 1, })
                if (props.autoPlay === true) {
                    vid.play();
                } else {
                    vid.pause();
                }

                gsap.to('.videoPopUpContainer .dsRoundBtn ', { backgroundColor: '' })
                gsap.to('.videoPopUpContainer .parentTmpButton', { opacity: 0.5, })
                gsap.to('.videoPopUpContainer .dsRoundBtn div', { color: '' })
                gsap.to('.videoPopUpContainer .videoPopUpWrapper .parentTmpButton', { scale: 1, })
            });
        }, VideoPopUpRef)
        return () => ctx.revert();
    }, []);

    let checkMobile = vwMaxMedium
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            overlayTL.current && overlayTL.current.progress(0).kill();
            overlayTL.current = gsap.timeline({ defaults: { duration: .5, }, })
                .set(overlayRef.current, { rotateX: 30, display: 'flex', duration: 0, })
                .to(overlayRef.current, { top: '5vh', rotateX: 0, })
                .from('.overlayVideoWrapper', {
                    y: '10vh', scaleX: .25, opacity: 0, duration: 1.25, ease: "Power3.easeInOut"
                }, "-=1")

        }, overlayRef)
        return () => ctx.revert();
    }, []);
    useEffect(() => {
        overlayTL.current.reversed(!overlayOpen)
    })

    function toggleOverlay() {
        setOverlayOpen(!overlayOpen)
        console.log('test',overlayOpen)
        !overlayOpen ? jwPlayerRef.current.player.play() : jwPlayerRef.current.player.pause()
        if (!overlayOpen && !vwMaxMedium) { document.body.style.overflowY = "hidden" }
        gsap.to('.exitBtn .parentTmpButton', { opacity: 1, delay: 1.5, })

        const scrollElement = document.getElementById('StandaardVideo');
        const basicHeader = document.getElementById('basicHeader');


        if (!overlayOpen && props.hideHeader === true) {
            setTimeout(() => {
                gsap.to(['#vidPlayHeader', '#vidPlayHeader >*'], { y: '-10vh', scale: 1, stagger: .1, opacity: 0, })
                gsap.to('#innerStandaardOverlay', { opacity: 0, })

            }, 200);
        } else {
            setTimeout(() => {
                gsap.to(['#vidPlayHeader', '#vidPlayHeader >*'], { y: '0vh', stagger: .1, opacity: 1, })
                gsap.to('#innerStandaardOverlay', { opacity: 1, })

            }, 400);
        }


        setTimeout(() => {
            setTimeout(() => {
                if (overlayOpen) { document.body.style.overflowY = "unset" }
                if (!overlayOpen) {
                    // console.log(overlayOpen, 'open of niet')
                    gsap.set('.basicHeader', { display: 'none' })
                    gsap.to(basicHeader, {
                        y: '-20vh', onComplete: () => { gsap.set(basicHeader, { display: 'none' }) }
                    })

                } else if (overlayOpen) {
                    gsap.to(basicHeader, {
                        y: '-0vh', onStart: () => { gsap.set(basicHeader, { display: 'flex' }) }
                    })
                    gsap.set('.basicHeader', { display: 'flex' })

                }
            }, 500);
            if (scrollElement) {
                // console.log(overlayOpen, 'test is topen')
                if (!overlayOpen) {
                    scrollElement.scrollIntoView({ behavior: 'smooth', alignToTop: true, });
                }
                if (!vwMaxMedium) {
                    gsap.to(".videoPopUpBgExit", { delay: .35, opacity: overlayOpen ? 0 : 1, display: overlayOpen ? "none" : "block", })
                }
            }
        }, 450);
    }

    return (
        <div ref={VideoPopUpRef} id="StandaardVideo" className="videoPopUpContainer">
            <Media queries={{ maxMedium: props.vw.maxMedium }} onChange={matches => matches.maxMedium ? setVwMaxMedium(true) : setVwMaxMedium(false)} />
            <h3 className="vidTitle">{props.title}</h3>

            <div className="bgDivider">
                <div className="bgDividerBlur"></div>
            </div>
            <>
                <div className="videoPopUpBgExit" ></div>

                <div className="videoPopUpWrapper" >
                    <video id="videoPopUpElement" className="videoPopUpElement"
                        src={props.localVideo ? props.videoFilmRelease : props.data.mp4} loop muted></video>
                    <div className="videoPopUpMfLayer" onClick={toggleOverlay}></div>
                    <TmpButton title="" type="round" icon="play_circle" anim="scale" className="dsBtn tmpBtn tmpBtnScaleVideo" link="" />
                </div>
                <div ref={overlayRef} className="videoPopUpFixedOverlay">
                    <div className="exitBtn" onClick={toggleOverlay}>
                        <TmpButton dashedline={false} title="" type="round" icon="close" anim="scale" className="dsBtn tmpBtn" link="" />
                    </div>
                    <div className="overlayVideoWrapper">
                        <JWPlayer
                            ref={jwPlayerRef}
                            file={`https://cdn.jwplayer.com/manifests/${props.jwVideoID ? props.jwVideoID : props.data.videos[0]}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${props.jwVideoID ? props.jwVideoID : props.data.videos[0]}-640.jpg`}
                            library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
                            asd={`https://cdn.jwplayer.com/libraries/${props.jwPlayerID ? props.jwPlayerID : 'zfNclFT1'}.js`}
                        />
                    </div>
                </div>
            </>
            {/* {vwMaxMedium ? <JWPlayer
                ref={jwPlayerRefSingle}
                file={`https://cdn.jwplayer.com/manifests/${props.data.videos[0]}.m3u8`}
                image={`https://cdn.jwplayer.com/thumbs/${props.data.videos[0]}-640.jpg`}
                library='https://cdn.jwplayer.com/libraries/zfNclFT1.js'
            /> :
            } */}
        </div >
    )
}
