
// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 
import React, { Fragment } from 'react';
import Media from 'react-media';
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SwiperComponent } from '../../../../components/swiperComponent/swiperComponentCore';
import swiperComp from '../components/SwiperComponent.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import { EffectCards } from "swiper";
import { EffectFade } from "swiper";
import { Autoplay } from "swiper";
import { TmpButton } from '../../../../components/buttons/tempButton';


// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-cards";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-fade";


gsap.registerPlugin(ScrollTrigger);
export const DfwCoreImageViewer = (props) => {


    const StandaardImageViewerRef = useRef();
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {



        }, StandaardImageViewerRef)
        return () => ctx.revert();
    }, []);
    return (
        <section ref={StandaardImageViewerRef} className="StandaardImageViewer DfwImageViewer" id='StandaardImageViewer'
            style={{
                paddingTop: swiperComp[props.id].swiperSettings.fullWidth ? 0 : '',
            }}>
            {/* <div className='bgLayer'></div> */}
            <h2>{props.title}</h2>
            {/* <ArticleHz title="h2" data={dataSwiper} /> */}

            {
                swiperComp.map((component, index) => (
                    props.id === index ?
                        < SwiperComponent key={index} compData={component}
                            localImages={props.localImages}
                            allLocalImages={props.allLocalImages}
                            aantalSliderImages={props.aantalSliderImages}
                        />
                        : null
                ))
            }
            <div className='SwiperComponentCoreWrapper'>

            </div>

        </section>
    )
}