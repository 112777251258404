import React from 'react';
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { gsap } from "gsap"; import { ScrollTrigger } from "gsap/ScrollTrigger"; import { ScrollSmoother } from "gsap/ScrollSmoother"; import MouseFollower from "mouse-follower";
import JWPlayer from '@jwplayer/jwplayer-react';
import { Helmet } from 'react-helmet'

//ALLE DFW CORE MODULES
import { DfwHeader } from "./core/dfwCore/dfwHeader/dfwHeader";
import { DfwCoreHero } from "./core/dfwCore/dfwHero/core/dfwHero";
import { DfwCoreInfo } from "./core/dfwCore/dfwSection/core/DfwInfoCore";
import { DfwCoreImageViewer } from './core/dfwCore/dfwCoreImageViewer/core/DfwCoreImageViewer'
import { DfwCoreFooter } from './core/dfwCore/dfwFooterComponent/dfwFooterComponent';
import { MoreInfo } from './core/dfwCore/moreInfo/MoreInfo';

//ALLE DFW ASSETS
import googleCalndrIcon from './core/dfwCore/assetsDfw/google-brands.svg'
import microsoftCalndrIcon from './core/dfwCore/assetsDfw/microsoft-brands.svg'
import appleCalndrIcon from './core/dfwCore/assetsDfw/apple-brands.svg'

import logoFilmRelease from './core/dfwCore/assetsDfw/logo-film-release.webp'
import posterFilmRelease from './core/dfwCore/assetsDfw/poster.webp'
// import videoFilmRelease from './core/dfwCore/assetsDfw/dfwvid.mp4'
import facebookIcon from './core/dfwCore/assetsDfw/fbicon.svg'
import instagramIcon from './core/dfwCore/assetsDfw/instaicon.svg'
import youtubeIcon from './core/dfwCore/assetsDfw/ytbicon.svg'
import tiktokIcon from './core/dfwCore/assetsDfw/tiktokicon.svg'
import dfwLogo from './core/dfwCore/assetsDfw/logodfw.png'
import slideImage1 from './core/dfwCore/assetsDfw/slideImage-1.webp'
import slideImage2 from './core/dfwCore/assetsDfw/slideImage-2.webp'
import slideImage3 from './core/dfwCore/assetsDfw/slideImage-3.webp'
import slideImage4 from './core/dfwCore/assetsDfw/slideImage-4.webp'
import slideImage5 from './core/dfwCore/assetsDfw/slideImage-5.webp'
import slideImage6 from './core/dfwCore/assetsDfw/slideImage-6.webp'
import slideImage7 from './core/dfwCore/assetsDfw/slideImage-7.webp'
import slideImage8 from './core/dfwCore/assetsDfw/slideImage-8.webp'
import slideImage9 from './core/dfwCore/assetsDfw/slideImage-9.webp'
import slideImage10 from './core/dfwCore/assetsDfw/slideImage-10.webp'


import footerIcon1 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-1.png'
import footerIcon2 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-2.png'
import footerIcon3 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-3.png'
import footerIcon4 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-4.png'
import footerIcon5 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-5.png'
import footerIcon6 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-6.png'
import footerIcon7 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-7.png'
import footerIcon8 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-8.png'
import footerIcon9 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-9.png'
import footerIcon10 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-10.png'
import footerIcon11 from './core/dfwCore/assetsDfw/footer-icons/footerlogo-11.png'
import singleImage from './core/dfwCore/assetsDfw/single-image.webp';



// DATA
import inputDfwData from './core/dfwCore/input.json'
import { ZetInAgendaModule } from './components/zetInAgendaComponent/zetInAgendaComponent';
import { ImageComponent } from './components/ImageComponent/ImageComponentCore';
import { IntroComponentLayerd } from './components/introComponent/introComponents';
MouseFollower.registerGSAP(gsap);
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
const options = { method: 'GET', headers: { accept: 'application/json' } };

export function TemplateDfw() {
    const refCore = useRef();
    const refJwPlayerTop = useRef();
    const breakpoints = { small: "(max-width: 599px)", medium: "(min-width: 600px) and (max-width: 1199px)", maxMedium: "(max-width: 1199px)", large: "(min-width: 1200px)" }
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.from(['#DfwHero #StandaardVideo video',], { scale: 2, duration: 2, })
            gsap.from('#DfwHero .videoPopUpWrapper', { duration: 1, scale: .5, })
            gsap.set("#StandaardOverlay", { opacity: 0, pointerEvents: 'auto' })
            gsap.set("#StandaardOverlay", { delay: 1.5, pointerEvents: 'none', })
            gsap.to("#StandaardOverlay", { delay: 1.5, opacity: 1, duration: 2, })
        }, refCore)
        return () => ctx.revert();
    }, []);

    return (
        <div ref={refCore} className={`core body dfwTempCore ${inputDfwData[0].isBasicTemplate ? 'DFWCOREBASIC' : 'DFWCOREVIDEO'} `} >
            <Helmet>
                <meta charSet="utf-8" />
                <title>{inputDfwData[0].title + ' | Dutch FilmWorks'}</title>
                <meta name="description" content={inputDfwData[0].synopsis} />
                <meta property="og:title" content={inputDfwData[0].title + ' | Dutch FilmWorks'} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={inputDfwData[0].synopsis}></meta>
                <meta property="og:title" content={inputDfwData[0].title + ' | Dutch FilmWorks'} />
                <meta property="og:image" content={slideImage1} />
                <link rel="icon" type="image/png" href={dfwLogo} sizes="16x16" />
                <script defer>{`
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://campage.matomo.cloud/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '26']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/campage.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  })();
                `}</script>
            </Helmet>
            <IntroComponentLayerd />
            <DfwHeader
                icons={[facebookIcon, instagramIcon, youtubeIcon, tiktokIcon]}
                socialUrls={[inputDfwData[0].facebookUrl, inputDfwData[0].instagramUrl, inputDfwData[0].youtubeUrl, inputDfwData[0].tiktokUrl]}
                datum={inputDfwData[0].datumFilm}
                buttonTxt={inputDfwData[0].showZetInAgendaModule ? 'Zet in je agenda' : 'Koop je tickets'} slideImage8
            />
            {
                !inputDfwData[0].isBasicTemplate ?
                    <DfwCoreHero
                        buttonTxt={inputDfwData[0].showZetInAgendaModule ? 'Zet in je agenda' : 'Koop je tickets'}
                        vw={breakpoints}
                        logoFilmRelease={logoFilmRelease}
                        autoPlay={true}
                        localVideo={true}
                        // videoFilmRelease={videoFilmRelease}
                        oneLiner={inputDfwData[0].oneLiner}
                        jwPlayerRef={refJwPlayerTop}
                        bunnyTeaseVideo={inputDfwData[0].bunnyTeaseVideo}
                        jwPlayerID={inputDfwData[0].jwPlayerID}
                        jwVideoID={inputDfwData[0].jwVideoID}
                        title={inputDfwData[0].title}
                        lengteFilm={inputDfwData[0].lengteFilm}
                        datumFilm={inputDfwData[0].datumFilm}
                        labelFilm={inputDfwData[0].labelFilm}
                    />
                    : null
            }
            <DfwCoreInfo
                id={1}
                buttonTxt={inputDfwData[0].showZetInAgendaModule ? 'Zet in je agenda' : 'Koop je tickets'}
                textComp={true} swiperComp={false} imageComp={true}
                columns={2}
                vw={breakpoints}
                localImages={true}
                posterFilmRelease={posterFilmRelease}
                localContent={true}
                title={inputDfwData[0].titleSynopsis}
                txt={inputDfwData[0].synopsis}
                checkIfBasic={inputDfwData[0].isBasicTemplate}
                logoFilmRelease={logoFilmRelease}
            />
            {inputDfwData[0].isBasicTemplate ? <div id="DfwCoreJwPlaceHodler">
                <div className="wrapper" style={{ paddingTop: "0vh" }}>
                    <h2>{inputDfwData[0].titleTrailer}</h2>
                    <div className="container-overflow">
                        <JWPlayer
                            file={`https://cdn.jwplayer.com/manifests/${inputDfwData[0].jwVideoID}.m3u8`}
                            image={`https://cdn.jwplayer.com/thumbs/${inputDfwData[0].jwVideoID}-640.jpg`}
                            library={`https://cdn.jwplayer.com/libraries/${inputDfwData[0].jwPlayerID}.js`}
                        />
                    </div>
                </div>
            </div> : null
            }
            <div id="DfwTicketModule">
                {!inputDfwData[0].showZetInAgendaModule ? <h2>{inputDfwData[0].titleTickets + ' ' + inputDfwData[0].title}</h2> : null}
                {inputDfwData[0].showZetInAgendaModule ?
                    <ZetInAgendaModule
                        icons={[googleCalndrIcon, appleCalndrIcon, microsoftCalndrIcon]}
                        title={'Mis ' + inputDfwData[0].title + ' niet en zet de film alvast in je agenda!'}
                        // subTitle={`Mis ${inputDfwData[0].title} niet en zet de film alvast in je agenda!`}
                        links={inputDfwData[0].zetInAgendaModule}
                    />
                    : null
                }
            </div>
            {
                inputDfwData[0].isBasicTemplate ? null : <>
                    <DfwCoreImageViewer
                        id={0}
                        vw={breakpoints}
                        localImages={true}
                        title={inputDfwData[0].titleImageSlider + inputDfwData[0].title}
                        aantalSliderImages={inputDfwData[0].aantalSliderImages}
                        allLocalImages={[slideImage1, slideImage2, slideImage3, slideImage4, slideImage5, slideImage6, slideImage7, slideImage8, slideImage9, slideImage10]}
                    />
                    <div id="DfwCoreJwPlaceHodler">
                        <div className="wrapper">
                            <h2>{inputDfwData[0].titleTrailer}</h2>
                            <div className="container-overflow">
                                <JWPlayer
                                    file={`https://cdn.jwplayer.com/manifests/${inputDfwData[0].jwVideoID}.m3u8`}
                                    image={`https://cdn.jwplayer.com/thumbs/${inputDfwData[0].jwVideoID}-640.jpg`}
                                    library={`https://cdn.jwplayer.com/libraries/${inputDfwData[0].jwPlayerID}.js`}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                inputDfwData[0].isBasicTemplate ?
                    <section id="DfwSingleImageSection">
                        <ImageComponent
                            compData={{
                                image: singleImage,
                                coverBackgroundImage: false,
                                title: "title Image",
                                buyButton: false, bgLayer: true,
                                button: {
                                    show: false, title: "ImageTitlename", className: "tmpBtn ",
                                    link: {
                                        scrollTo: true, anchorID: "StandaardRetail",
                                        exitClick: "https://www.lipsum.com/feed/html", openNewTab: true
                                    },
                                    icon: { icon: "shopping_cart", animation: "left" }
                                }
                            }}
                        />
                        <h3 id='DfwTagline'>{inputDfwData[0].oneLiner}
                        </h3>
                    </section>
                    : null
            }

            <MoreInfo />
            <DfwCoreFooter logo={dfwLogo} logoFilmRelease={logoFilmRelease}
                maxFooterIcons={inputDfwData[0].iconsFooterAantal}
                footerIcons={[footerIcon1, footerIcon2, footerIcon3, footerIcon4, footerIcon5, footerIcon6, footerIcon7, footerIcon8, footerIcon9, footerIcon10, footerIcon11]}
            />
        </div>
    )
}
