import { getDisplayName } from "@mui/utils";
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useState, useRef } from "react";

export const IntroComponentLayerd = (props) => {
    const introLayerdSection = useRef();

    useEffect(() => {
        gsap.to(['.introInner', '.introOuter'], {
            height: '0vh', ease: "Power4.easeInOut",
            duration: 1.75, stagger: .25,
            y: '-10vh',
        })
        gsap.to('.introLayerdSection', {
            display: 'none',
            delay:1.5,
        })
    }, [])

    return (
        <div ref={introLayerdSection} className="introLayerdSection">
            <div className="introLayerdWrapper">
                <div className="introOuter">
                    <div className="introInner"></div>
                </div>
            </div>
        </div>
    )
}