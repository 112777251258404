import React, { Fragment } from 'react';
import { gsap } from "gsap";


export const ZetInAgendaModule = (props) => {
    console.log()
    return (
        <section id="ZetInAgendaModule">
            <h2>{props.title}</h2>
            <p>{props.subTitle}</p>
            <div className='zetInAgendaBtnWrapper'>
                {/* https://calndr.link/ */}
                <a href={props.links[0].google} target="_blank">
                    <button className='tmpBtn dfwBtn'>
                        <img src={props.icons[0]} />
                        Google Calendar
                    </button>
                </a>
                <a href={props.links[1].apple} target="_blank">
                    <button className='tmpBtn dfwBtn'>
                        <img src={props.icons[1]} />
                        Apple Calendar
                    </button>
                </a>
                <a href={props.links[2].outlook} target="_blank">
                    <button className='tmpBtn dfwBtn'>
                        <img src={props.icons[2]} />
                        Outlook Calendar
                    </button>
                </a>
            </div>
        </section>
    )
}